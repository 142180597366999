<template lang="pug">
  div
    .container-fluid#wrapper
      #title ユーザー検索
      #search-condition
        #keyword
          #search-form(action="" method="get")
            input.form-control(name="s" type="text" placeholder="ユーザー名で検索する" v-model="keyword" @keydown.enter="onKeydownEnter()")
            #search-btn(@click="searchUser()")
              i.fas.fa-search
      #main-content
        #search-results {{resultMessage}}
        #table 
          .table-row(@click="callRouterInfo(item)" v-for="(item,index) in userList" :value="item")
            .badgeIcon
              img(:src="createImgPath(item.iconPath)" v-if="!item.iconPath==''")
            .name(@click="callRouterInfo(item)") {{item.name}}
</template>

<script>
import axios from 'axios'

export default {
  // データオブジェクト
  props:{
  },
  data() {
    return {
      userList:[],
      keyword:'',
      resultMessage:'',
    }
  },
  created(){
    this.getData('')
  },
  // メソッド
  methods: {
    onKeydownEnter() {
      this.searchUser()
    },
    searchUser(){
      this.getData(this.keyword)
    },
    async getData(keyword) {
      let searchKeyword = ''
      if(keyword != undefined){
        searchKeyword = keyword
      }
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-userlist'
      await axios
        .get(jsonUrl,{
          params: {
            "keyword" : searchKeyword
          },         
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {
          this.userList = response.data
          this.resultMessage = '検索結果は' + this.userList.length + '件です'
          //ログインしている場合、ログインユーザーのデータは公開範囲に関わらず参照可能とする
          if(this.$user.isLogin){
            this.userList.forEach(user => {
              if(user.userId == this.$user.userId){
                user.isPrivate = false
              }
            })
          }
        })
        .catch(err => {
          this.resultMessage = '情報の取得に失敗しました'
          this.userList = []
        })
    },
    callRouterInfo(item){
      this.$router.push('/myPage/' + item.userId +'/userInfoTop')
    },
    createImgPath(path){
      return this.getStatusIconPath(path)
    },
  },
  // コンポーネント
  components: {
  }
}
</script>
<style lang="stylus" scoped>
#wrapper
  padding-top 10px

  #title
    margin-top 2rem
    text-align center
    font-size 1.5rem
    font-weight 600  
    color #0664A9

  //検索条件
  #search-condition
    height 30px
    margin-bottom 3.5rem
    #keyword
      #search-form
        position relative
        margin 3rem 0
        #search-btn
          position absolute
          right 3%
          top 10%
          background none
          color #666
          border none
          font-size 20px
        #search-btn 
          &:hover 
            cursor pointer
            color #006fbe

  #main-content
    #search-results
      margin 0.5rem 0
    #table
      margin-bottom 3rem
      .table-row
        height 50px
        display flex
        justify-content center
        align-items center
        width 100%
        background #eceeef
        color #374047
        margin-bottom 2px
        cursor pointer
        .badgeIcon
          width 25%
          text-align center
          img
            width 40px
            height 40px
            border-radius 50%   
        .name
          width 75%
          font-weight 500
          text-decoration underline
          padding-left 0.5rem
      .table-row:hover
        background #2d8fd5
        .name
          color #FFF
        .badgeIcon
          img
            filter brightness(120%)

@media (min-width 992px)
  #wrapper
    width 50%
    #main-content
      #table
        margin-bottom 4rem
        .table-row
          height 70px
          .badgeIcon
            width 25%
            img
              width 50px
              height 50px 
          .name
            width 75%

</style>